import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Auth.css';
import { Box, Container, CircularProgress, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SignUp from './AuthForms.js/SignUp.js';
import SignIn from './AuthForms.js/SignIn.js';
import ForgotPassword from './AuthForms.js/ForgotPassword.js';
import useLoadingState from '../../context/LoadingContext.js';
import ChangeAuthForm from './AuthForms.js/ChangeAuthForm.js';
import { useAuthContext } from '../../context/AuthContext.js';
import { ToastContainer, toast } from 'react-toastify';

export default function Auth() {
  const { type } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isAuthenticated, error, user, email } = useAuthContext();

  const { loginLoading, pageLoading, setLoginLoading } = useLoadingState();

  // for sign up- if user hasn't verified email yet, show toast
  useEffect(() => {
    const needsVerification = localStorage.getItem('needsVerification');

    if (needsVerification === 'true') {
      toast.success(`Successful sign up!  Please check your email to verify your account. `, {
        theme: 'colored',
        draggable: true,
        draggablePercent: 60,
        toastId: 'useAuth-1',
        autoClose: false,
      });
      localStorage.removeItem('needsVerification');
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated && window.localStorage.length > 0) {
        navigate('/login-landing');
      }
    }, 1200);
  }, [isAuthenticated, navigate]);

  const authForms = () => {
    switch (type) {
      case 'sign-in':
        return <SignIn />;

      case 'sign-up':
        return <SignUp />;

      case 'forgot-password':
        return <ForgotPassword />;

      default:
        break;
    }
  };

  return (
    <>
      {!loginLoading && !pageLoading ? (
        <Box className={'auth-form-wrapper'}>
          <Container maxWidth={'xs'} className={'auth-form-container'}>
            <Box className="form-container" component={'form'} onSubmit={(e) => e.preventDefault()}>
              <Paper className="form-container-paper" elevation={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                    height: '100%',
                    justifyContent: 'space-evenly',
                  }}
                >
                  {authForms()}
                </Box>

                <Container maxWidth="sm" className="sign-in-sign-out">
                  <ChangeAuthForm type={type} />
                </Container>
              </Paper>
            </Box>
          </Container>
        </Box>
      ) : (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '49%',
            left: '49%',
          }}
        />
      )}
    </>
  );
}
