import PostCard from '../PostCard/PostCard.js';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import DashboardSubMgt from '../Subscription/SubscriptionPages/DashboardSubMgt/DashboardSubMgt.js';
import { downloadInventoryCSV } from '../../services/fetch-utils.js';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { fetchStripeCustomerPortal } from '../../services/stripe.js';
import useLoadingState from '../../context/LoadingContext.js';
import Inventory from '../Inventory/Inventory.js';
import { useState } from 'react';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@emotion/react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UploadIcon from '@mui/icons-material/Upload';
export default function Dashboard({
  products,
  setProducts,
  restricted,
  posts,
  loading,
  setPosts,
  customerId,
}) {
  // state

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { setPageLoading } = useLoadingState();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  // Filter and then slice the posts for pagination
  const filteredPosts = posts.filter(
    (post) => !selectedCategory || post.category === selectedCategory
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // Slice the filteredPosts to show only the posts for the current page
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  // functions
  const navigate = useNavigate();

  const handleDownloadCSV = () => {
    downloadInventoryCSV();
  };

  const handleNewPost = () => {
    navigate('/dashboard/new');
  };

  const handleOpenCustomerPortal = async () => {
    setPageLoading(true);
    const data = await fetchStripeCustomerPortal({ customerId });

    return data;
  };

  const handleNavToRenewSubscription = () => {
    navigate('/subscription');
  };

  return (
    (loading && (
      <div className="loading-dash">
        <FlamePipe />
      </div>
    )) || (
      <>
        <Box className="mobile-dashboard-buttons">
          <Button
            size="small"
            variant="contained"
            onClick={handleNewPost}
            sx={{
              fontSize: '.7rem',
              padding: '10px 5px',
            }}
            disabled={restricted ? restricted : false}
          >
            New Post
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={handleDownloadCSV}
            sx={{ fontSize: '.7rem', padding: '10px 5px' }}
          >
            Inventory CSV
          </Button>
          {!restricted ? (
            <Button
              size="small"
              variant="contained"
              onClick={handleOpenCustomerPortal}
              sx={{ fontSize: '.7rem', padding: '10px 5px' }}
            >
              Manage Subscription
            </Button>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'yellow',
                color: 'black',
                borderRadius: '5px',
                width: '100px',
              }}
            >
              {/* <Button className={'expired-subscription'}></Button> */}
              <Button
                // variant="body2"
                size="small"
                textAlign={'center'}
                padding={'10px'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'yellow',
                  color: 'black',
                  borderRadius: '5px',
                  width: '100px',
                  fontSize: '.7rem',
                  lineHeight: '1rem',
                }}
                onClick={handleNavToRenewSubscription}
              >
                Subscription Expired!
              </Button>
            </Box>
          )}
        </Box>

        <Box
          className="admin-container"
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.primary.dark,
            padding: 0,
            transform: 'translateY(-3%)',
          }}
        >
          <aside className="admin-panel ">
            <section className="admin-panel-section ">
              <div className="button-container">
                {' '}
                <Typography variant="h5">Product Management</Typography>
                <div className="inner-button-container">
                  {
                    <Button
                      title="New Post"
                      size="medium"
                      variant="contained"
                      onClick={handleNewPost}
                      disabled={restricted ? restricted : false}
                      startIcon={<UploadIcon />}
                      sx={{ width: '300px', marginTop: '20px' }}
                    >
                      {restricted ? 'New Post disabled' : 'New Post'}
                    </Button>
                  }
                  <Button
                    size="medium"
                    variant="outlined"
                    className="new-link download-button"
                    title="Download Inventory CSV"
                    onClick={handleDownloadCSV}
                    startIcon={<CloudDownloadIcon />}
                    sx={{ width: '300px' }}
                  >
                    Inventory CSV
                  </Button>
                </div>
                <Box
                  sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: (theme) => theme.palette.primary.dark,
                  }}
                  className="small-size-inventory"
                >
                  <Button disabled={!selectedCategory} onClick={() => setSelectedCategory(null)}>
                    Show All Categories
                  </Button>
                  <Inventory
                    posts={posts}
                    onCategorySelect={setSelectedCategory}
                    selectedCategory={selectedCategory}
                  />
                </Box>
                <div className="temp-fix"></div>
                <Typography variant="h5" style={{ textAlign: 'center', paddingLeft: '0px' }}>
                  Subscription Management
                </Typography>
                <DashboardSubMgt />
              </div>
            </section>
          </aside>

          <div className="list-container">
            {/* Pagination Controls */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px',
                justifySelf: 'center',
              }}
            >
              <Button
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Typography mx={2} sx={{ color: 'green' }}>
                Page {currentPage} of {Math.ceil(filteredPosts.length / postsPerPage)}
              </Typography>
              <Button
                onClick={() =>
                  setCurrentPage((prevPage) =>
                    currentPage >= Math.ceil(filteredPosts.length / postsPerPage)
                      ? prevPage
                      : prevPage + 1
                  )
                }
                disabled={currentPage >= Math.ceil(filteredPosts.length / postsPerPage)}
              >
                Next
              </Button>
            </Box>

            {posts.length === 0 ? (
              <>
                {posts.length === 0 && (
                  <div className="loading">
                    <h1>No posts yet!</h1>
                  </div>
                )}
                {posts.length !== 0 && <div style={{ height: '100px' }} />}
                <FlamePipe />
              </>
            ) : (
              // posts
              //   .filter((post) => !selectedCategory || post.category === selectedCategory)
              //   .map((post) => (
              //     <PostCard
              //       key={post.id}
              //       {...post}
              //       setPosts={setPosts}
              //       posts={posts}
              //       products={products}
              //       setProducts={setProducts}
              //     />
              //   ))
              currentPosts.map((post) => (
                <PostCard
                  key={post.id}
                  {...post}
                  setPosts={setPosts}
                  posts={posts}
                  products={products}
                  setProducts={setProducts}
                  restricted={restricted}
                />
              ))
            )}
          </div>

          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: (theme) => theme.palette.primary.dark,
            }}
            className="large-size-inventory"
          >
            <Accordion defaultExpanded={!isMobile}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Inventory/ Category Selector
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  style={{ marginTop: '0px' }}
                  disabled={!selectedCategory}
                  onClick={() => {
                    setSelectedCategory(null);
                    setCurrentPage(1);
                  }}
                >
                  Show All Categories
                </Button>

                <Inventory
                  posts={posts}
                  selectedCategory={selectedCategory}
                  onCategorySelect={setSelectedCategory}
                  setCurrentPage={setCurrentPage}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
          {/*  */}
          {/*  */}
        </Box>
      </>
    )
  );
}
