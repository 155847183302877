import { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function useProfile(sub) {
  const [error, setError] = useState('');
  const [profileLoading, setProfileLoading] = useState(true);

  // AWS Cognito related data:
  const [profile, setProfile] = useState({});

  // Strtipe related data:
  const [bizProfile, setBizProfile] = useState({});

  // Profile posts related data:
  const [posts, setPosts] = useState([]);

  const fetchProfile = useCallback(async () => {
    if (!sub) return;
    try {
      setProfileLoading(true);

      const resp = await fetch(`${BASE_URL}/api/v1/profile/${sub}`, {
        method: 'GET',
        credentials: 'include',
        cache: 'no-store', // Bypass cache to avoid old data
      });

      if (resp.status === 204) {
        setError('Profile not found.');
        return;
      }

      const data = await resp.json();

      if (resp.ok) {
        setProfile(data.profile);
        setBizProfile(data.bizProfile);
        setPosts(data.posts);
        setProfileLoading(false);
      } else if (resp.status === 404) {
        resp.data = 'yo dawg';
      } else {
        throw new Error('Failed to fetch profile.', data.message); // This will be caught by the catch block
      }
    } catch (e) {
      setError(e.message);
      // eslint-disable-next-line no-console
      console.error('Failed to fetch profile.', e.message);
      // setProfileLoading(false);
      toast.error(`Error fetching profile: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
        toastId: 'useProfile-1',
        autoClose: false,
      });
    }
  }, [sub]);

  useEffect(() => {
    // Using an immediately-invoked async function inside useEffect
    (async () => {
      await fetchProfile(); // Directly await the profile fetching
    })();
  }, [fetchProfile]);

  const updateProfile = async (updatedData) => {
    try {
      const resp = await fetch(`${BASE_URL}/api/v1/profile/user-update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedData),
      });

      const data = await resp.json();

      if (!resp.ok) {
        throw new Error(data.message);
      }

      // Set the state directly from the update response
      setProfile(data.profile);
      // setBizProfile(data.bizProfile);

      // Then, re-fetch the data after the update to ensure frontend matches backend
      // Promise.all([fetchProfile(), fetchStripeCustomer()]).then(() => setProfileLoading(false));
      await fetchProfile();
      setProfileLoading(false);
    } catch (e) {
      setError(e.message);
      // setProfileLoading(false);
      toast.error(`Error updating profile: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
        toastId: 'useProfile-2',
        autoClose: false,
      });
    }
  };

  const updateBizProfile = async (updatedBizData) => {
    try {
      const resp = await fetch(`${BASE_URL}/api/v1/profile/customer-update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedBizData),
      });

      const data = await resp.json();

      if (!resp.ok) {
        throw new Error('Error updating business profile.', data.message);
      }

      // Assuming your backend returns the updated biz profile in the same structure
      setBizProfile(data);
    } catch (e) {
      console.error('Error updating business profile.', e.message); // You can replace this with a more user-friendly error message
      toast.error(`Error updating business profile: ${e}`, {
        theme: 'dark',
        draggable: true,
        draggablePercent: 60,
        toastId: 'useProfile-3',
        autoClose: false,
      });
      return;
    }
  };

  return {
    error,
    profileLoading,

    profile,
    bizProfile,
    updateProfile,
    updateBizProfile,
    posts,
    fetchProfile,
  };
}
